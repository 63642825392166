<template>
  <!-------------------------- start view -------------------------->
  <div >
    <div :style="$root.mobile ? '' : 'display:flex;    height: 60px; align-items: center;'" class="py-2 px-1 ">
    <h4 class="text-primary font-weight-bolder">
      {{$t("MF.personal")}}
    </h4>
    </div>
    <div v-if="mf" class="mb-2 listitem">
      <v-list-item style="min-height:80px">
        <v-list-item-avatar size="47" color="primary">
          <v-img
            v-if="mf != null && mf.owner.avatar"
            :src="mf.owner.avatar.data.thumbnails[0].url"
          />
          <div
            v-else
            style="text-transform: uppercase;width:100%"
            class="white--text font-size-h4"
          >
            {{ mf.owner.first_name.charAt(0) }}
          </div>
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="listitemtitle">
            {{ mf.full_name }} <br />
            <div style="padding-top:5px; color: #9e9e9e;">
              {{ mf.gender }} - {{ mf.birthday | moment("from", true) }} old
            </div>
          </div>
        </v-list-item-content>
        <v-list-item-action v-if="$root.mobile">
          <v-btn icon @click="showdetails = !showdetails">
            <v-icon color="primary">{{
              showdetails ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-expand-transition>
        <div v-show="showdetails || !$root.mobile">
          <div>
            <!-------- item 1 ----------->
            <v-list-item>
              <v-list-item-avatar>
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-11-19-154210/theme/html/demo4/dist/../src/media/svg/icons/General/User.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="cardtitle">
                  {{ $t("MF.infobox.dob") }}
                </div>
              </v-list-item-content>
              <v-list-item-action>
                {{ mf.birthday }}
              </v-list-item-action>
            </v-list-item>
            <!-------- item 2 ----------->
            <v-list-item>
              <v-list-item-avatar>
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-11-19-154210/theme/html/demo4/dist/../src/media/svg/icons/Home/Ruller.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M15,7 L15,8 C15,8.55228475 15.4477153,9 16,9 C16.5522847,9 17,8.55228475 17,8 L17,7 L19,7 L19,10 C19,10.5522847 
        19.4477153,11 20,11 C20.5522847,11 21,10.5522847 21,10 L21,7 C22.1045695,7 23,7.8954305 23,9 L23,15 C23,16.1045695 22.1045695,17 21,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,9 C1,7.8954305 1.8954305,7 3,7 L3,10 C3,10.5522847 3.44771525,11 4,11 C4.55228475,11 
        5,10.5522847 5,10 L5,7 L7,7 L7,8 C7,8.55228475 7.44771525,9 8,9 C8.55228475,9 9,8.55228475 9,8 L9,7 L11,7 L11,10 C11,10.5522847 11.4477153,11 12,11 C12.5522847,11 13,10.5522847 13,10 L13,7 L15,7 Z"
                        fill="#000000"
                        transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) "
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="cardtitle">
                  {{ $t("MF.infobox.height") }}
                </div>
              </v-list-item-content>
              <v-list-item-action>
                {{ mf.height }} {{ $t("MF.infobox.hSuffix") }}
              </v-list-item-action>
            </v-list-item>
            <!-------- item 3 ----------->
            <v-list-item>
              <v-list-item-avatar>
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-11-19-154210/theme/html/demo4/dist/../src/media/svg/icons/Home/Weight2.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(12.023129, 11.752757) rotate(33.000000) translate(-12.023129, -11.752757) "
                        x="11.0231287"
                        y="7.92751491"
                        width="2"
                        height="7.65048405"
                        rx="1"
                      />
                      <path
                        d="M7.5,3 L16.5,3 C18.9852814,3 21,5.01471863 21,7.5 L21,15.5 C21,17.9852814 18.9852814,20 16.5,20 L7.5,20 C5.01471863,20 3,17.9852814 3,15.5 L3,7.5 C3,5.01471863 5.01471863,3 7.5,3 Z M6,7.88235294 L8.57142857,12 L15.4285714,12 L18,7.88235294 C17,5.96078431 15,5 12,5 C9,5 7,5.96078431 6,7.88235294 Z"
                        fill="#000000"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="cardtitle">
                  {{ $t("MF.infobox.weight") }}
                </div>
              </v-list-item-content>
              <v-list-item-action>
                {{ mf.weight }} {{ $t("MF.infobox.wSuffix") }}
              </v-list-item-action>
            </v-list-item>
            <!-------- item 3 ----------->
            <v-list-item>
              <v-list-item-avatar>
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-11-19-154210/theme/html/demo4/dist/../src/media/svg/icons/Design/Color.svg-->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M12,21 C15.8659932,21 19,17.8659932 19,14 C19,11.4226712 16.6666667,8.08933783 12,4 C7.33333333,8.08933783 5,11.4226712 5,14 C5,17.8659932 8.13400675,21 12,21 Z"
                        fill="#000000"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="cardtitle">
                  {{ $t("MF.infobox.btype") }}
                </div>
              </v-list-item-content>
              <v-list-item-action>
                {{ mf.blood_type }}
              </v-list-item-action>
            </v-list-item>
          </div>
        </div>
      </v-expand-transition>
    </div>
    <div v-else class="mb-2 listitem">
      <v-list-item style="min-height:80px">
        <v-list-item-avatar size="47">
          <div
            style="text-transform: uppercase;width:100%"
            class="loadingcircle"
          ></div>
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="listitemtitle">
            <div class="loadingbar"></div>
            <br />
            <div class="loadingbar"></div>
          </div>
        </v-list-item-content>
        <v-list-item-action> </v-list-item-action>
      </v-list-item>
    </div>
  </div>
  <!-------------------------- end mobil view -------------------------->

 
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "patient_info",
  props: ["user", "mfid"],
  components: {
    
  },
  data() {
    return {
      mf: null,
      showdetails: false,
      error: "",
    };
  },
  methods: {
    async getPatientMF() {
      if (JSON.parse(localStorage.userInfo).role == 4 && localStorage.mfdetails) {

          this.mf = JSON.parse(localStorage.mfdetails);

      }
      else {
        console.log("getting mf");
          try {
            // var userInfo = JSON.parse(localStorage.userInfo).id;
            const { data } = await ApiService.get(
              "items",
              "medical_file/" +
                // `medical_file?filter[owner]=${userInfo}?fields=owner.avatar.data,owner.first_name,owner.last_name,height,weight,blood_type,birthday,gender,full_name`
                this.mfid +
                "?fields=owner.avatar.data,owner.first_name,owner.last_name,height,weight,blood_type,birthday,gender,full_name,gender"
            );
            this.mf = data.data;
            localStorage.mfdetails = JSON.stringify(data.data);
            this.$root.title = this.$t("MF.mf") + ": " + this.mf.full_name;
          } catch (error) {
            this.error = error;
            console.log(error);
          }
        }
    },
  },
  mounted() {
    console.log("mounted patient info");
  },
  activated() {
    console.log("activated patient info");
  },
  created() {
    this.getPatientMF();
  },
};
</script>

<style lang="scss" scoped>
.listitemtitle {
  margin: 0px !important;
}
.loadingcircle {
  background-color: #dddbdd;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.loadingbar {
  background-color: #dddbdd;
  overflow: hidden;
  height: 17px;
  width: 100%;
  border-radius: 5px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
